import React from 'react';
import Logo from '../img/logo.svg';

const Header = ({ activeTab, setActiveTab }) => {
  return (
    <header className="wiki-header">
      <div className="wiki-header-wrapper">
        <div className="wiki-header-left">
          <a href="/" className="logo">
            <img src={Logo} alt="Logo" className="logo-img" />
            <span className="wiki-title">이마월 - 가이드</span>
          </a>
        </div>
        <div className="wiki-header-right">
          <button className={`tab-link`}>
            준비중...
          </button>
          {/* <button
            className={`tab-link ${activeTab === 'rules' ? 'active' : ''}`}
            onClick={() => setActiveTab('rules')}
          >
            준비중...
          </button> */}
        </div>
      </div>
    </header>
  );
};

export default Header;